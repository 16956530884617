import * as React from 'react'
import styles from "./FormCheckbox.module.scss";
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
  id?: string,
  name?: string,
  className?: string,
  label: string,
  error?: boolean,
  value?: boolean,
  errorClassName?: string,
  required?: boolean,
  onChange?: (boolean) => void
}


const FormCheckbox: React.FC<Props> = (props) => {
  const {onChange, error, name, value, errorClassName, required, label, className, id} = props;


  const classNames = [styles.formCheckbox];
  const checkboxClassNames = [styles.checkbox];

  if (className) {
    classNames.push(className);
  }
  if (error) {
    classNames.push(styles.error);
    classNames.push(errorClassName);
  }

  if (value) {
    checkboxClassNames.push(styles.checked)
  }

  return <div className={classNames.join(" ")}><input
    name={name}
    id={id}
    type="checkbox"
    checked={value}/>
    <div onClick={() => onChange(!value)} className={checkboxClassNames.join(" ")}>
      <FontAwesomeIcon icon={faCheck}/>
    </div>
    <label onClick={() => onChange(!value)} htmlFor={id}>{label}{required ? <span className={styles.required}>*</span> : ""}</label>
  </div>

}


export default FormCheckbox
