import React, {useState} from "react"
import styles from "../styles/pages/contact.module.scss";
import Container from "../components/Container/Container";
import {CONSTS, STATIC_TEXTS} from "../resources/staticTexts";
import axios from "axios";
import PageHeader from "../components/PageHeader/PageHeader";
import FormTextField from "../components/Form/FormTextField/FormTextField";
import FormButton from "../components/Form/FormButton/FormButton";
import FormCheckbox from "../components/Form/FormCheckbox/FormCheckbox";

const Contact = () => {
  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [permission, setPermission] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState(false);
  const [penalty, setButtonPenalty] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    });
    return formData
  }
  const alowedTypes = ["jpg", "jpeg", "png", "pdf"];
  const handleSubmit = e => {
    e.preventDefault();
    setButtonDisabled(true);

    setErrorMessage(undefined);
    setSuccessMessage(false);
    if (file && ((file.size || 0) / 1024 / 1024 > 5 || !alowedTypes.includes(file.name?.split('.').pop() || "unknown"))) {
      setErrorMessage(STATIC_TEXTS.fileErrorMessage);
      setButtonDisabled(false);
      return;
    }

    if (validateFields().length) {
      setButtonDisabled(false);
      return;
    }

    const data = {"form-name": "Contact_Form", name, email, message, file, permission}

    const axiosOptions = {
      url: location.pathname,
      method: "post",
      headers: {"Content-Type": "multipart/form-data"},
      data: encode(data)
    }

    axios(axiosOptions)
      .then(() => {
        setSuccessMessage(true)
      })
      .catch(() => {
        setErrorMessage(STATIC_TEXTS.errorMessage);
      })
      .finally(() => {
        setEmail(undefined);
        setFile(undefined);
        setName(undefined);
        setMessage(undefined);
        setPermission(undefined);
        setButtonPenalty(true);
      })

    setTimeout(() => {
      setButtonPenalty(false)
      setButtonDisabled(false)
    }, 30000);

  };

  const validateFields = (): string[] => {
    let newErrors: string[] = [];
    if (!name) {
      newErrors.push("name")
    }
    if (!message) {
      newErrors.push("message")
    }
    if (!permission) {
      newErrors.push("permission")
    }
    setErrors(newErrors);
    return newErrors;
  }

  return <Container className={styles.contactPage}>
    <PageHeader header={STATIC_TEXTS.contact} description={STATIC_TEXTS.contactDescription}/>
    <form id={CONSTS.FORM} name={CONSTS.FORM} method={"POST"} data-netlify="true" autoComplete="off"
          onSubmit={event => handleSubmit(event)}>
      <input type="hidden" name="form-name" value={CONSTS.FORM}/>
      <FormTextField
        id={STATIC_TEXTS.name}
        value={name}
        name={"name"}
        onChange={setName}
        required
        maxLength={50}
        error={errors.includes("name")}
        label={STATIC_TEXTS.name}/>
      <FormTextField
        id={STATIC_TEXTS.emailAddress}
        value={email}
        name={"email"}
        maxLength={50}
        onChange={setEmail}
        label={STATIC_TEXTS.emailAddress}/>
      <FormTextField
        id={STATIC_TEXTS.message}
        value={message}
        name={"message"}
        onChange={setMessage}
        required
        multiline
        maxLength={5000}
        error={errors.includes("message")}
        label={STATIC_TEXTS.message}/>
      {STATIC_TEXTS.attachement} <input accept={".png,.jpg,.jpeg,.pdf"}
                                        onChange={(e) => setFile(e?.target?.files?.[0])} type="file" id="file"
                                        name="file"/>
      <FormCheckbox
        label={STATIC_TEXTS.rodoAccept}
        required
        name={"permission"}
        error={errors.includes("permission")}
        onChange={setPermission}
        id={"permission"}
        value={permission}/>
      {errorMessage && <span className={styles.error}>{errorMessage}</span>}
      {successMessage && <span className={styles.success}>{STATIC_TEXTS.successMessage}</span>}
      {penalty && <span className={styles.neutral}>{STATIC_TEXTS.wait}</span>}
      <FormButton disabled={buttonDisabled}
                  type={"submit"}
                  label={STATIC_TEXTS.submit}
      />
    </form>
  </Container>;
}

export default Contact
