import * as React from 'react'
import {useState} from 'react'
import styles from "./FormTextField.module.scss";

type Props = {
  value: string,
  name?: string,
  id?: string,
  className?: string,
  required?: boolean,
  label?: string,
  onChange: (string) => void
  error?: boolean,
  errorClassName?: string,
  maxLength?: number,
  multiline?: boolean
}


const FormTextField: React.FC<Props> = (props) => {
  const {error, onChange, label, value, name, id, multiline, className, required, errorClassName, maxLength} = props;
  const [loaderClasses, setLoaderClasses] = useState([]);
  const [labelClasses, setLabelClasses] = useState([]);

  const onChangeWrapper = (event) => {
    if (maxLength && event.target.value.length > maxLength) {
      onChange(value);
    } else {
      onChange(event.target.value)
    }
  }

  const classNames = [styles.formInput];


  if (className) {
    classNames.push(className);
  }
  if (error) {
    classNames.push(errorClassName);
    classNames.push(styles.error);
  }
  if (multiline) {
    classNames.push(styles.multiline);
  }

  const commonProps = {
    onFocus: () => {
      setLoaderClasses([styles.showLoader])
      setLabelClasses([styles.labelActive])
    },
    onBlur: () => {
      setLoaderClasses([styles.hideLoader])
      if (!value) {
        setLabelClasses([styles.labelInactive])
      }
    },
    id: id,
    value: value,
    name: name,
    onChange: onChangeWrapper,
    ariaLabel: label
  }

  return <div className={classNames.join(" ")}>
    <label htmlFor={id} className={labelClasses.join(" ")}>{label}{required ?
      <span className={styles.required}>*</span> : ""}</label>
    <div className={styles.inputWrapper}>
      {multiline ? <textarea {...commonProps}/> :
        <input {...commonProps}/>
      }
      <div className={styles.loaderWrapper}>
        <div className={[styles.loader, ...loaderClasses].join(" ")}/>
        <div className={[styles.loaderTransformed, ...loaderClasses].join(" ")}/>
      </div>
    </div>
  </div>

}


export default FormTextField
