import * as React from 'react'
import styles from "./FormButton.module.scss";
import {useRef} from "react";

type Props = {
  id?: string,
  disabled?: boolean,
  className?: string,
  label: string,
  type?: string
  onClick?: () => void
}


const FormButton: React.FC<Props> = (props) => {
  const {onClick, type, label, className, id, disabled} = props;
  const classNames = [styles.button];
  const ref = useRef();

  if (className) {
    classNames.push(className);
  }

  return <button disabled={disabled} ref={ref} id={id} onClick={onClick} type={type} className={classNames.join(" ")}>{label}</button>

}


export default FormButton
